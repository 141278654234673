@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

$main: var(--bitcoinmart-colors-orange-50);

.polka {
  background-color: #e5e5f7;
  background-image: radial-gradient(
    var(--bitcoinmart-colors-orange-200) 0.9500000000000001px,
    $main 0.9500000000000001px
  );
  background-size: 19px 19px;
}

.smooth {
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
  }
}

.md {
  h1,
  h2,
  h3 {
    margin-top: 1rem;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }
  ul,
  ol {
    margin-left: 1rem;
  }

  ol {
    margin-top: 1rem;
  }
  strong {
    font-weight: bold;
  }
}
